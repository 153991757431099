import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
//import AuthHelperMethods from '../../components/AuthHelperMethods';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
//import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

class Profile extends Component {
    constructor(props) {
		super(props);
		this.state= {
            snackbaropen: false,
            alertMessage: '',
            alertMessageType: '',
            btnLoading: false,
            userDetails: JSON.parse(localStorage.getItem('user')),
            profileDetails: [],
            tabValue: 0,
            isLoaderOpen: true,
            updateBtn: true,
            ProfilePasswordDatas: [],
            apiUrl: process.env.REACT_APP_API_URL
		}
        //const userDetails = JSON.parse(localStorage.getItem('user'));
        this.getUserProfileDetails()
	}


getUserProfileDetails = async() => {
    this.setState({isLoaderOpen: true})
    const email = this.state.userDetails.email;
    const requestType = 'userProfileDetails';
    fetch(`${this.state.apiUrl}/profile/profile.php`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email, requestType
        })
    })
    .then(res => res.json())
    .then(json => {
        this.setState({
            profileDetails: json.data[0],
            isLoaderOpen: false
        });
    })
}


handleCurrentPass = (e) => {
    if(e.target.value != this.state.profileDetails.login_password){
        this.setState({
            alertMessageType:'warning',
            alertMessage: 'Current Password is Wrong, Please Try Again',
            snackbaropen: true,
            updateBtn: true
        })
    }else{
       this.setState({updateBtn: false});
    }
};

handleProfilePasswordInput = (e) => {
    let val = e.target.value;
    var name = e.target.name;
    var obj = {...this.state.ProfilePasswordDatas};
    obj[name] = val;
    this.setState({ ProfilePasswordDatas: obj })
}

handlePasswordUpdate = (e) => {
    if(this.state.ProfilePasswordDatas.newPassword != this.state.ProfilePasswordDatas.confirmPassword){
        this.setState({
            alertMessageType:'warning',
            alertMessage: 'New Password and Current Password does not match!',
            snackbaropen: true,
        })
    }else{
        const email = this.state.userDetails.email;
        const password = this.state.ProfilePasswordDatas.newPassword;
        const requestType = 'updateUserPassword';
        fetch(`${this.state.apiUrl}/profile/profile.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email, password, requestType
            })
        })
        .then(res => res.json())
        .then(json => {
            if(json.status === 200){
                this.setState({
                    alertMessageType:'success',
                    alertMessage: 'New Password is updated successfully!',
                    snackbaropen: true,
                });
            }else{
                this.setState({
                    alertMessageType:'error',
                    alertMessage: 'Failed to update New Password !',
                    snackbaropen: true,
                });
            }
        })
    }
}

handleTabChange = (event, newValue) => {
    this.setState({tabValue: newValue});
};

handleClose = () => {
    this.setState({snackbaropen: false});
};

render() {
    const { userDetails, profileDetails, isLoaderOpen, updateBtn } = this.state;
    
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
      
function a11yProps(index) {
return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
};
}
      
const useStyles = makeStyles((theme) => ({
root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
},
}));

return (
<React.Fragment>   
    <Backdrop className="backDropLoader" open={isLoaderOpen} >
        <CircularProgress color="inherit" />
    </Backdrop>
    <div id="profilePage">
        <Snackbar
            anchorOrigin={{  vertical: 'top', horizontal: 'right' }}
            open={this.state.snackbaropen}
           // onClose={this.handleClose}
            autoHideDuration={2000}
            key={{vertical: 'top', horizontal: 'right'}}
        >
            <Alert onClose={this.handleClose} severity={this.state.alertMessageType}>
                {this.state.alertMessage}
            </Alert>
        </Snackbar>
        <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
            <Link color="inherit" href="/" >
                Home
            </Link>
            <Typography color="textPrimary" >
                Profile
            </Typography>
        </Breadcrumbs>
        <div id="profilePageContent">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={2} >
                    <div className="leftSide">
                        <h4 className="profileInfoHead">Profile Information</h4>
                        <div className="profileImg">
                            <img width="150" src={userDetails.profile_pic} />
                        </div>
                        <div className="profileDetails">{userDetails.first_name} {userDetails.last_name}</div>
                        <div className="profileDetails">{userDetails.email}</div>
                        <div className="profileDetails">Role: {userDetails.user_type}</div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10} >
                    <div className="rightSide">
                        <h4 className="profileInfoHead">Update Profile Information</h4>
                        <div className="profileTabs">
                            <AppBar position="static">
                                <Tabs 
                                    value={this.state.tabValue} 
                                    onChange={this.handleTabChange} 
                                    aria-label="simple tabs example"
                                    variant="fullWidth"
                                >
                                    <Tab label="Personal Information" wrapped {...a11yProps(0)} />
                                    <Tab label="Password" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.tabValue} index={0}>
                                <div class="personalInfoDet">
                                    <Grid container >
                                        <Grid item xs={12} sm={2} >
                                            <div className="profileDetHead">
                                                Name:
                                            </div>
                                            <div className="profileDetHead">
                                                Email:
                                            </div>
                                            <div className="profileDetHead">
                                                Phone:
                                            </div>
                                            <div className="profileDetHead">
                                                User Type (role):
                                            </div>
                                            <div className="profileDetHead">
                                                Created On:
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={2} >
                                            <div className="profileDetText">
                                                {profileDetails.first_name} {profileDetails.last_name}
                                            </div>
                                            <div className="profileDetText">
                                                {profileDetails.login_email}
                                            </div>
                                            <div className="profileDetText">
                                                {profileDetails.phone}
                                            </div>
                                            <div className="profileDetText">
                                                {profileDetails.user_type}
                                            </div>
                                            <div className="profileDetText">
                                                {profileDetails.created_on}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={1}>
                                <div class="passwordSection">
                                    <Grid container >
                                        <Grid item xs={12} sm={2} style={{marginTop: '6px'}}>
                                            <div className="profileDetHead">
                                               Current Password:
                                            </div>
                                            <div className="profileDetHead">
                                                New Password:
                                            </div>
                                            <div className="profileDetHead">
                                                Confirm Password:
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={2} >
                                            <div className="profileDetText">
                                                <TextField
                                                    type="password"
                                                    onBlur={this.handleCurrentPass}
                                                    placeholder="Current Password"
                                                />
                                            </div>
                                            <div className="profileDetText">
                                                <TextField
                                                    name='newPassword'
                                                    placeholder="New Password"
                                                    type="password"
                                                    onBlur={this.handleProfilePasswordInput}
                                                />
                                            </div>
                                            <div className="profileDetText">
                                                <TextField
                                                    name='confirmPassword'
                                                    placeholder="Confirm Password"
                                                    type="password"
                                                    onBlur={this.handleProfilePasswordInput}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Button onClick={this.handlePasswordUpdate} disabled={updateBtn}>Update</Button>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>


        
    </div>
</React.Fragment>
)
}

}export default Profile;