import React from 'react';
import {Pie, Doughnut} from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import { Line } from 'react-chartjs-2';


const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'My First dataset',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40]
      }
    ]
  };


const state = {
  labels: ['January', 'February', 'March',
           'April', 'May'],
  datasets: [
    {
      label: 'Rainfall',
      backgroundColor: [
        '#B21F00',
        '#C9DE00',
        '#2FDE00',
        '#00A6B4',
        '#6800B4'
      ],
      hoverBackgroundColor: [
      '#501800',
      '#4B5000',
      '#175000',
      '#003350',
      '#35014F'
      ],
      data: [65, 59, 80, 81, 56]
    }
  ]
}

export default class App extends React.Component {
    constructor(props) {
		super(props);
		this.state= {
            leadData:[],
		}
        const userDetails = JSON.parse(localStorage.getItem('user'));
        this.getListOfLeads(userDetails)
	}

    getListOfLeads = async(userDetails) => {
        this.setState({ tableLoading: true });
        const centreId = userDetails.centre_id;
        fetch("https://backendportal.24sevensupportservices.com/api/leads/listofLeads.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
              centreId
            })
        })
        .then(res => res.json())
        .then(json => {
             const yearFilters = json.data.filter((filter) => filter.is_confirmed === '1' && filter.cus_name === 'Richard');
            
             console.log(yearFilters);
            // this.setState({
            //     leadData: json.data,
            //     tableLoading: false,
            //     alertMessage: 'Lead data is loaded successfully!',
            //     snackbaropen: true
            // });
        })
    }



      

  render() {
    return (
      <div>
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Pie
                    data={state}
                    options={{
                        title:{
                        display:true,
                        text:'Average Rainfall per month',
                        fontSize:20
                        },
                        legend:{
                        display:true,
                        position:'right'
                        }
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <Doughnut
                    data={state}
                    options={{
                        title:{
                        display:true,
                        text:'Average Rainfall per month',
                        fontSize:20
                        },
                        legend:{
                        display:true,
                        position:'right'
                        }
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <Line ref="chart" data={data} />
            </Grid>
        </Grid>
        

        
      </div>
    );
  }
}