import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
//import AuthHelperMethods from '../../components/AuthHelperMethods';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
//import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ImageUploading, { ImageListType } from "react-images-uploading";



const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class AddCars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbaropen: false,
            alertMessage: '',
            alertMessageType: '',
            tableLoading: true,
            btnLoading: false,
            apiUrl: process.env.REACT_APP_API_URL,

            images: [],
            inputFieldDatas: {}

        }
        const userDetails = JSON.parse(localStorage.getItem('user'));
    }

    async componentDidMount() {
        this.setState({ tableLoading: false })
    }



    handleInputValue = (e) => {        
            let val = '';
            var idaa = e.target.name;
            let fieldName = '';
            //const val = e.target.checked;
            let validateField = idaa.split('_');
            if(validateField.length === 1){
                val = e.target.value;
                fieldName = e.target.name;
            }else{
                val = e.target.checked ? 1 : 0;
                let validateField_2 = validateField.slice(0,-1);
                fieldName = validateField_2.join('_');
            }

            var obj = {...this.state.inputFieldDatas};
            obj[fieldName] = val;
            this.setState({ inputFieldDatas: obj })
            
        console.log('obj',obj)        
    }


    _handleLogout = () => {
        this.Auth.logout()
        this.props.history.replace('/login');
    }



    createNewCar = event => {
        event.preventDefault();
        this.setState({btnLoading:true});
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const userName = userDetails.first_name+''+userDetails.last_name
        const newCarInputValues = this.state.inputFieldDatas;
        const images = this.state.images;
        const requestType = 'addNewCar';
        fetch(`${this.state.apiUrl}/cars/addCar.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userName,
                newCarInputValues,
                images,
                requestType
            })
        })
        .then(res => {
            console.log(res);
            console.log(res.status);
            if(res.status == '200'){
                this.setState({
                    btnLoading:false
                });
                window.location.href = "/#/cars";
            }
        })
    };




    render() {

        const IOSSwitch = withStyles((theme) => ({
            root: {
                width: 42,
                height: 26,
                padding: 0,
                margin: theme.spacing(1),
            },
            switchBase: {
                padding: 1,
                '&$checked': {
                    transform: 'translateX(16px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        backgroundColor: '#52d869',
                        opacity: 1,
                        border: 'none',
                    },
                },
                '&$focusVisible $thumb': {
                    color: '#52d869',
                    border: '6px solid #fff',
                },
            },
            thumb: {
                width: 24,
                height: 24,
            },
            track: {
                borderRadius: 26 / 2,
                border: `1px solid ${theme.palette.grey[400]}`,
                backgroundColor: theme.palette.grey[50],
                opacity: 1,
                transition: theme.transitions.create(['background-color', 'border']),
            },
            checked: {},
            focusVisible: {},
        }))(({ classes, ...props }) => {
            return (
                <Switch
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked,
                    }}
                    {...props}
                />
            );
        });



        const onChange = (imageList,addUpdateIndex) => {
            // data for submit
            console.log(imageList, addUpdateIndex);
            this.setState({images: imageList});
          };
          const maxNumber = 69;

        return (
            <React.Fragment>
                <div id="leadsPage">
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.snackbaropen}
                        onClose={this.handleClose}
                        autoHideDuration={4000}
                        key={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={this.handleClose} severity={this.state.alertMessageType}>
                            {this.state.alertMessage}
                        </Alert>
                    </Snackbar>
                    <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
                        <Link color="inherit" href="/" >
                            Home
                        </Link>
                        <Typography color="textPrimary" >
                            Add Cars
                        </Typography>
                    </Breadcrumbs>
                    <div className="leadContent">
                        <Grid container spacing={3} className="headerBlock">
                            <Grid item xs={6}>
                                <a href="/#/cars"><div className="headerText" style={{display:'inline-flex', color: 'firebrick'}}> <ArrowCircleLeftIcon style={{color: 'firebrick'}} /> Back to list of cars</div></a>
                            </Grid>
                            <Grid item xs={6} className="addLeadBtn" >

                            </Grid>
                        </Grid>

                        <div className="leadTableViewSec">
                        <form 
                            autoComplete="off" 
                            onSubmit={this.createNewCar.bind(this)}
                        >
                            <div id="addNewCarPage">
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                name='make'
                                                onChange={this.handleInputValue}
                                                select
                                                label="Select Car Brand"
                                                variant="outlined"
                                                //defaultValue={row.lead_type}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                fullWidth={true} 
                                                required
                                            >
                                                <option value=""></option>
                                                <option value="Aston Martin">Aston Martin</option>
                                                <option value="Audi">Audi</option>
                                                <option value="Bentley">Bentley</option>
                                                <option value="BMW">BMW</option>
                                                <option value="Chevrolet">Chevrolet</option>
                                                <option value="Citroen">Citroen</option>
                                                <option value="Ferrari">Ferrari</option>
                                                <option value="Fiat">Fiat</option>
                                                <option value="Ford">Ford</option>
                                                <option value="Honda">Honda</option>
                                                <option value="Hummer">Hummer</option>
                                                <option value="Hyundai">Hyundai</option>
                                                <option value="Isuzu">Isuzu</option>
                                                <option value="Jaguar">Jaguar</option>
                                                <option value="Jeep">Jeep</option>
                                                <option value="Mercedes-Benz">Mercedes-Benz</option>
                                                <option value="Mini">Mini</option>
                                                <option value="Mitsubishi">Mitsubishi</option>
                                                <option value="Renault">Renault</option>
                                                <option value="Skoda">Skoda</option>
                                                <option value="Suzuki">Suzuki</option>
                                                <option value="Tesla">Tesla</option>
                                                <option value="Toyota">Toyota</option>
                                                <option value="Volkswagen">Volkswagen</option>
                                                <option value="Volvo">Volvo</option>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                className="material_input"
                                                placeholder="Car Modal Name"
                                                name="name"
                                                type="text"
                                                fullWidth="true"
                                                label="Car Modal Name"
                                                variant="outlined"
                                                onChange={this.handleInputValue}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                className="material_input"
                                                placeholder="KM Driven"
                                                name="kmDriven"
                                                type="number"
                                                fullWidth="true"
                                                label="KM Driven"
                                                variant="outlined"
                                                onChange={this.handleInputValue}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                className="material_input"
                                                placeholder="Mileage/Litre"
                                                name="mileage"
                                                type="number"
                                                fullWidth="true"
                                                label="Mileage/Litre"
                                                variant="outlined"
                                                onChange={this.handleInputValue}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                name='transmission'
                                                onChange={this.handleInputValue}
                                                select
                                                label="Transmission"
                                                variant="outlined"
                                                //defaultValue={row.lead_type}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                fullWidth={true} 
                                                required
                                            >
                                                <option value=""></option>
                                                <option value="Automatic">Automatic</option>
                                                <option value="Manual">Manual</option>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                className="material_input"
                                                placeholder="Seats"
                                                name="seats"
                                                type="number"
                                                fullWidth="true"
                                                label="Seats"
                                                variant="outlined"
                                                onChange={this.handleInputValue}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                className="material_input"
                                                placeholder="Luggage"
                                                name="luggageNumber"
                                                type="number"
                                                fullWidth="true"
                                                label="Luggage"
                                                variant="outlined"
                                                onChange={this.handleInputValue}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                name='fuel'
                                                onChange={this.handleInputValue}
                                                select
                                                label="Fuel"
                                                variant="outlined"
                                                //defaultValue={row.lead_type}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                fullWidth={true} 
                                                required
                                            >
                                                <option value=""></option>
                                                <option value="Petrol">Petrol</option>
                                                <option value="Diesel">Diesel</option>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Box>

                            <div className="" style={{marginBottom:'2%',marginTop:'2%', color:'firebrick', fontSize: '20px', fontWeight: 'bold'}}>Car Description</div>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Description"
                                        multiline
                                        rows={4}
                                        defaultValue=""
                                        fullWidth="true"
                                        variant="outlined"
                                        name="description"
                                        onChange={this.handleInputValue}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        name='rentalCategory'
                                        onChange={this.handleInputValue}
                                        select
                                        label="Rental Category"
                                        variant="outlined"
                                        //defaultValue={row.lead_type}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        fullWidth={true} 
                                        required
                                    >
                                        <option value=""></option>
                                        <option value="Day To Day">Day To Day</option>
                                        <option value="Luxury">Luxury</option>
                                        <option value="Rykers">Rykers</option>
                                    </TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Amount ($)"
                                        //multiline
                                        //rows={4}
                                        type="number"
                                        defaultValue=""
                                        fullWidth="true"
                                        variant="outlined"
                                        name="priceCar"
                                        onChange={this.handleInputValue}
                                    />
                                </Grid>
                            </Grid>

                            <div className="" style={{marginBottom:'2%',marginTop:'2%', color:'firebrick', fontSize: '20px', fontWeight: 'bold'}}>Car Features</div>

                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Airconditions"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.airconditions}  
                                                onChange={this.handleInputValue}
                                                name="airconditions_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Seat Belt"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.seat_belt}  
                                                onChange={this.handleInputValue}
                                                name="seat_belt_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Audio input"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.audio_input}  
                                                onChange={this.handleInputValue}
                                                name="audio_input_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Child Seat"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.child_seat}  
                                                onChange={this.handleInputValue}
                                                name="child_seat_switch"
                                            />}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Sleeping Bed"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.sleeping_bed}  
                                                onChange={this.handleInputValue}
                                                name="sleeping_bed_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Long Term Trips"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.long_term_trips}  
                                                onChange={this.handleInputValue}
                                                name="long_term_trips_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="GPS"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.gps}  
                                                onChange={this.handleInputValue}
                                                name="gps_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Water"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.water}  
                                                onChange={this.handleInputValue}
                                                name="water_switch"
                                            />}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Car Kit"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.car_kit}  
                                                onChange={this.handleInputValue}
                                                name="car_kit_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Luggage"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.luggage}  
                                                onChange={this.handleInputValue}
                                                name="luggage_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Bluetooth"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.bluetooth}  
                                                onChange={this.handleInputValue}
                                                name="bluetooth_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Remote central locking"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.remote_central_locking}  
                                                onChange={this.handleInputValue}
                                                name="remote_central_locking_switch"
                                            />}
                                        />
                                    </Grid>


                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Music"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.music}  
                                                onChange={this.handleInputValue}
                                                name="music_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Onboard computer"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.onboard_computer}  
                                                onChange={this.handleInputValue}
                                                name="onboard_computer_switch"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            size="small"
                                            label="Climate control"
                                            control={<IOSSwitch 
                                                defaultChecked={this.state.inputFieldDatas.climate_control}  
                                                onChange={this.handleInputValue}
                                                name="climate_control_switch"
                                            />}
                                        />
                                    </Grid>
                                </Grid>

                                <div className="" style={{marginBottom:'2%',marginTop:'2%', color:'firebrick', fontSize: '20px', fontWeight: 'bold'}}>Car Images</div>


                                <div id="newCarImageUploads" style={{marginTop: '3%'}}>
                                    <ImageUploading
                                        multiple
                                        value={this.state.images}
                                        onChange={onChange}
                                        maxNumber={maxNumber}
                                    >
                                        {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps
                                        }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <div id="listOfImagesAdded" {...dragProps}>
                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="image-item">
                                                                <img src={image.dataURL} alt="" width="100" />
                                                                <div className="image-item__btn-wrapper">
                                                                <button className="replaceBtn" onClick={() => onImageUpdate(index)}>Update</button>
                                                                <button className="removeBtn" onClick={() => onImageRemove(index)}>Remove</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        Drag and Drop Images
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} id="uploadImgCarBtns">
                                                    <span
                                                        style={isDragging ? { color: "red" } : undefined}
                                                        onClick={onImageUpload}
                                                        {...dragProps}
                                                        className="uploadImgBtn"
                                                    >
                                                        Click to Upload Images
                                                    </span>
                                                    <span className="removeImgBtn" onClick={onImageRemoveAll}>Remove all images</span>
                                                </Grid>
                                            </Grid>
                                            
                                            
                                            
                                        </div>
                                        )}
                                    </ImageUploading>
                                </div>


                                <div style={{textAlign:'center'}}>
                                    <a href="/#/cars" style={{textDecoration:'unset'}}><Button className="goBackNewCarBtn" >Go Back</Button></a>
                                    <Button className="createNewCarBtn" type="submit">{this.state.btnLoading && <CircularProgress disableShrink={true} size={20}/>} Create</Button>
                                </div>




                            </div>
                        </form>
                        </div>
                    </div>



                </div>

            </React.Fragment>
        );
    }

}

export default AddCars;