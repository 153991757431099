/**
 * Code from the below medium post, only updated for latest material UI, using a
 * Menu for the popup and with breakpoints that work.
 *
 * https://medium.com/@habibmahbub/create-appbar-material-ui-responsive-like-bootstrap-1a65e8286d6f
 */
 import React from "react";
 import { MenuItem } from "@material-ui/core";
 import { withStyles } from "@material-ui/core/styles";
 import ButtonAppBarCollapse from "./ButtonAppBarCollapse";
 import { NavLink } from 'react-router-dom';

 
 const styles = theme => ({
   root: {
     position: "relative",
     right: 0,
     left: "64px",
     width: '110%'
   },
   buttonBar: {
     [theme.breakpoints.down("xs")]: {
       display: "none"
     },
     margin: "10px",
     paddingLeft: "16px",
     right: 0,
     position: "relative",
     width: "100%",
     background: "transparent"
   }
 });

 function MenuCollapse(props) {
 //const MenuCollapse = props => (
  //const userDetails = this.props.userDet;
  const userDetails = JSON.parse(localStorage.getItem('user'));
  console.log(userDetails);
  return (


   <div id="navBarMenus" className={props.classes.root}>
     <ButtonAppBarCollapse>
       <MenuItem><NavLink to="/">Dashboard</NavLink></MenuItem>
       <MenuItem><NavLink to="/leads">Leads</NavLink> </MenuItem>
     </ButtonAppBarCollapse>
     <div className={props.classes.buttonBar} id="appbar-collapse">
        <NavLink to="/dashboard" activeClassName="active">Dashboard</NavLink>
        <NavLink to="/cars" activeClassName="active">Cars</NavLink>
        
     </div>
     
   
   </div>
  )
  };
 
 export default withStyles(styles)(MenuCollapse);
 