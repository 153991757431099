

 import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AuthHelperMethods from '../../components/AuthHelperMethods';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { NavLink } from 'react-router-dom';

var Auth = new AuthHelperMethods();

const _handleLogout = () => {
  console.log('asasasas')
  localStorage.removeItem("user")
  Auth.logout()
  window.location.reload();
}

console.log('profileMewnu');


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function ProfileMenuCollapse() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  

  const userDetails = JSON.parse(localStorage.getItem('user'));
const userPic = userDetails.profile_pic;

  return (
    <div class="profileMenuBtn">
        <IconButton
            edge="end"
            aria-label="account of current user"
            //aria-controls={menuId}
            //aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
        >
            {/* <AccountCircle />"https://eshendetesia.com/images/user-profile.png  */}
            {userPic === ''? <AccountCircle />:<img width="40px" src={userPic} />}
        </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <NavLink className="navLinkBar" to="/user-profile">
            <ListItemIcon className="profileIcons">
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </NavLink>

          
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon  className="profileIcons">
            <ExitToAppIcon  fontSize="small" />
          </ListItemIcon>
          <ListItemText onClick={_handleLogout} primary="Loguot" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
 