

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
//import { NavLink } from 'react-router-dom';

import MenuCollapse from './MenuCollapse';
import ProfileMenuCollapse from './ProfileMenuCollapse';
const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  navigation: {},
  toggleDrawer: {},
  appTitle: {}
};

function Navigation(props) {
  const { classes } = props;
  return (
    <AppBar position="fixed" className={classes.navigation}>
      <Toolbar>
          <img width="120" src="images/jpl-logo.png" />
          <MenuCollapse userDet = {'asd'} />
        {/* <NavLink to="/">Dashboard</NavLink>
          <NavLink to="/leads">Leads</NavLink> */}
           <ProfileMenuCollapse />
      </Toolbar>
    </AppBar>
  );
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Navigation);
