import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
import AuthHelperMethods from '../../components/AuthHelperMethods';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


class Login extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    Auth = new AuthHelperMethods();
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loginError: "",
            isLoaderOpen: false,
        };
    }
    
    /* Fired off every time the use enters something into the input fields */
    _handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.setState({isLoaderOpen: true});
            this.handleFormSubmit(event);
        }
    }

    handleFormSubmit = (e) => {
        
        e.preventDefault();
        /* Here is where all the login logic will go. Upon clicking the login button, we would like to utilize a login method that will send our entered credentials over to the server for verification. Once verified, it should store your token and send you to the protected route. */
        this.Auth.login(this.state.username, this.state.password)
            .then(res => {
                console.log(res);
                if (res.status === 400) {
                    return this.setState({loginError: 'Sorry those credentials dont exist!', isLoaderOpen: false})
                    //return alert("Sorry those credentials don't exist!");
                }else{
                    this.setState({isLoaderOpen: false});
                        this.props.history.replace('/');
                }
                
            })
            .catch(err => {
                alert(err);
            })
    }

    componentWillMount() {
        /* Here is a great place to redirect someone who is already logged in to the protected route */
        if (this.Auth.loggedIn())
            this.props.history.replace('/');
    }

    render() {
        console.log(this.state.loginError)
        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="main-wrapper login_container_block">
                <Box component="span" m={1} className="box ">
                    <div className="box-header">
                        <div className="logo">
                            <img src="images/jpl-logo.png" />
                        </div>
                        
                        <h1>Admin Login</h1>
                    </div>
                    <form 
                        className="box-form" 
                        onSubmit={this.handleFormSubmit}
                        onKeyDown={this.handleKeyPress}
                    >
                        <TextField 
                            className="material_input"
                            placeholder="Username"
                            name="username"
                            type="text"
                            fullWidth = "true"
                            onChange={this._handleChange} 
                            label="Email" 
                            variant="outlined" 
                        />
                        <TextField 
                            className="material_input"
                            placeholder="Password"
                            name="password"
                            type="password"
                            fullWidth = "true"
                            onChange={this._handleChange} 
                            label="Password" 
                            variant="outlined" 
                        />
                        {/* <div className="remember_forgot">
                            <FormControlLabel
                                control={
                                <Checkbox
                                    // checked={state.checkedB}
                                    // onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Remember me"
                            />
                            <div className="forgot_password">Forget Password</div>
                        </div> */}
                        <div className="formBtn" >
                            <Button 
                                variant="outlined" 
                                className="submitBtn" 
                                fullWidth = "true"
                                size="large"
                                type="submit"
                                //onClick={this.handleFormSubmit}
                            >
                                Login
                            </Button>
                        </div>
                        <div className="loginErrorMsg">{this.state.loginError}</div>
                    </form>
                    {/* <Link className="link" to="/signup">Don't have an account? <span className="link-signup">Signup</span></Link> */}
                        
                </Box>
                       {/* <div className="signiture">
                        <h1>Template Built & Designed by Roman Chvalbo</h1>
                    </div> */}
                </div>
                
            </React.Fragment>
        );
    }

}

export default Login;