import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
import AuthHelperMethods from '../../components/AuthHelperMethods';
import Donut from "./Donut";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

class Dashboard extends Component {
    Auth = new AuthHelperMethods();
    constructor(props) {
		super(props);
		this.state= {
			countsData: [],
            isLoaderOpen: true,
            vicidialPanel: false,
            random: 0,
            profileDetails: JSON.parse(localStorage.getItem('user')),
            apiUrl: process.env.REACT_APP_API_URL,
            apiKey: 'da731be431a0e7d95f01f98888ac94c1'
		}
        const userDetails = JSON.parse(localStorage.getItem('user'));
	}
	
	async componentDidMount(){
		this.setState({isLoaderOpen: false})
	}
    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
   

  

_handleLogout = () => {
    this.Auth.logout()
    this.props.history.replace('/login');
}
                

    
    


render() {
    const { countsData, isLoaderOpen, vicidialPanel, profileDetails } = this.state;
    
    
    
return (
<React.Fragment>
    <Backdrop className="backDropLoader" open={isLoaderOpen} >
        <CircularProgress color="inherit" />
    </Backdrop>
    <div id="dashboardPage">
            {/* asdfasdf
        <button onClick={this._handleLogout}>LOGOUT</button> */}
        <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
            <Typography color="textPrimary" >
                Dashboard
            </Typography>
        </Breadcrumbs>
        

        HIIIII

    </div>
</React.Fragment>
);
}

}

export default Dashboard;